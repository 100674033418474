import './style';
import Router, {Link} from 'preact-router';
import {Component} from 'preact';
import {ApolloClient, InMemoryCache} from '@apollo/client';
import {SignIn} from "./app/core/login";
import {SignUp} from "./app/core/signup";
import {Start} from "./app/flow/start"

class App extends Component {
    constructor() {
        super();
        this.client = new ApolloClient({
            uri: 'http://localhost:8080/graphql',
            cache: new InMemoryCache(),
        });
    }

    render() {
        return (
            <Router>
                <div path="/">
                    <Link href="/login">Login</Link>
                </div>
                <div path="/login">
                    <SignIn/>
                </div>
                <div path="/signup">
                    <SignUp/>
                </div>
                <div path="/start">
                    <Start/>
                </div>
                <div path="/route">
                    <p>Test</p>
                    <Link href="/">Joe</Link>
                </div>
            </Router>
        );
    }
}

export default App;
