import {Component} from "preact";
import {Base} from "../core/components/base";
import {Box, Button, TextField} from "@mui/material";

export class Start extends Component {
    onSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        alert(data.get("url"));
    }

    render() {
        return (
            <Base>
                <Box component="form" onSubmit={this.onSubmit} sx={{mt: 3}}>
                    <TextField
                        required
                        fullWidth
                        id="url"
                        label="GET-request API URL"
                        name="url"
                        type="url"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >Go!</Button>
                </Box>
            </Base>
        )
    }
}
